import './_Popup.scss'
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
import blackLogo from "../../assets/images/black-logo.svg";
import {useEffect} from "react";


const MmailchimpSubscribe = ({closeForm}) => {
    const postUrl = `https://pivothawk.us19.list-manage.com/subscribe/post?u=07f7ac1a10dc5a24e04db56f6&amp;id=4e388138f3&amp;f_id=007689e3f0`;
    const {
        loading,
        error,
        success,
        message,
        handleSubmit
    } = useMailChimpForm(postUrl);
    const { fields, handleFieldChange } = useFormFields({
        EMAIL: "",MERGE1:""
    });
    useEffect(()=>{

        if(success){
            setTimeout(()=>{
                closeForm(true)
            },5000)
        }
    },[success])

    return (
        <div className="mc__form-container">
            <div>
                <div className='logo'><a href="/"><img src={blackLogo} alt="logo"/></a></div>
                <h2>Subscribe</h2>
                <h4>Stay ahead of the curve and be the first to know when PivotHawk is ready to transform your farming experience. Subscribe now and be part of the agricultural revolution!</h4>

                <form
                    onSubmit={event => {
                        event.preventDefault();
                        handleSubmit(fields);
                    }}
                >
                    <input
                        id="EMAIL"
                        autoFocus
                        placeholder="You email*"
                        type="email"
                        value={fields.EMAIL}
                        onChange={handleFieldChange}
                    />
                    <input
                        id="MERGE1"
                        autoFocus
                        placeholder="You message..."
                        type="text"
                        value={fields.MERGE1}
                        onChange={handleFieldChange}
                    />
                    <button>submit</button>
                </form>
               <div className='message'>
                   {loading && "submitting"}
                   {error && (message === '0 - Please enter a value' ? 'Please enter a value Email' : message)}
                   {success && message}
               </div>
            </div>
        </div>
    );
};

export default MmailchimpSubscribe
